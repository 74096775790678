import { render, staticRenderFns } from "./summary-report-unreturned.vue?vue&type=template&id=15a7c2f1"
import script from "./summary-report-unreturned.vue?vue&type=script&lang=js"
export * from "./summary-report-unreturned.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20241214021018Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15a7c2f1')) {
      api.createRecord('15a7c2f1', component.options)
    } else {
      api.reload('15a7c2f1', component.options)
    }
    module.hot.accept("./summary-report-unreturned.vue?vue&type=template&id=15a7c2f1", function () {
      api.rerender('15a7c2f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/summary-report-unreturned.vue"
export default component.exports